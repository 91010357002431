<template>
  <ion-page>
    <div class="background--modal" />
    <ion-header class="ion-no-border">
      <ion-toolbar color="transparent">
        <ion-item
          v-if="selectedView === 1"
          slot="start"
          lines="none"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal"
        >
          <img src="../../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-item
          v-else-if="selectedView === 2"
          slot="start"
          lines="none"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="goBackFunc('basket')"
        >
          <img src="../../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-item
          v-else
          slot="start"
          lines="none"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="goBackFunc('checkout')"
        >
          <img src="../../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-title
          v-if="selectedView === 1"
          class="text-18 text--white"
        >
          {{ $t('cart') }}
        </ion-title>
        <ion-title
          v-else-if="selectedView === 2"
          class="text-18 text--white"
        >
          {{ $t('Checkout') }}
        </ion-title>
        <ion-title
          v-else-if="selectedView === 3 || selectedView === 4"
          class="text-18 text--white"
        >
          {{ $t('Edit information') }}
        </ion-title>
        <ion-title
          v-else-if="selectedView === 5"
          class="text-18 text--white"
        >
          {{ $t('Shipping method') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div
        v-if="selectedView === 1"
        key="1"
      >
        <div v-if="cart.length">
          <div
            v-for="item in cart"
            :key="item"
          >
            <ion-item
              detail="false"
              lines="none"
              color="transparent"
              class="item rounded-corners mt-8 text--white"
            >
              <img
                slot="start"
                class="item-picture"
                :src="item.picture"
              >
              <div>
                <div class="text-14 ml-10">
                  {{ item.name }}
                </div>
                <div
                  class="text-left text-12 ml-10"
                  style="min-width: 100px; margin-top: 3px;"
                >
                  <span
                    v-if="item.paymentStyle === 'subscription'"
                  >
                    <span :style="[isKitInBasket || isMember ? 'text-decoration: line-through; font-size: 12px; color: rgba(217, 225, 217, 0.5);' : '', item.itemCategory === 'Homevision Kit' && !item.memberSubscriptionPriceMonth ? 'text-decoration: unset; color: white;': '' ]">
                      {{ item.subPrice }} kr</span> <span v-if="isMember || isKitInBasket && item.memberSubscriptionPriceMonth && item.itemCategory !== 'Homevision Kit'"> {{ item.memberSubscriptionPriceMonth }} kr</span> <span class="text--primary"> / {{ $t('perMonth') }} <span
                        v-if="item.qty > 1"
                        class="text--primary"
                      >x {{ item.qty }}</span></span><br>
                    <span
                      v-if="item.subscriptionUpfrontPrice"
                      class="text-10 text--white"
                    >{{ item.subscriptionUpfrontPrice }} kr {{ $t('Upfrontcost') }} <span
                      v-if="item.qty > 1"
                      class="text--primary"
                    >x {{ item.qty }}</span></span>
                  </span>
                  <span
                    v-else-if="item.paymentStyle === 'onetime'"
                  >
                    <!-- <span v-if="item.discountedPrice"><span style="text-decoration: line-through;">{{ item.price }}</span> <span class="text--primary">{{ item.price - item.discountedPrice }} kr</span></span> -->
                    <span>{{ item.price }} kr <span
                      v-if="item.qty > 1"
                      class="text--primary"
                    >x {{ item.qty }}</span></span>
                  </span>
                </div>
              </div>
              <!-- <div
                slot="end"
                class="item-qty"
              >
                <ion-input :value="item.qty" />
              </div> -->
              <div
                slot="end"
              >
                <i
                  class="mdi mdi-minus-circle-outline text--primary mr-10"
                  style="font-size: 25px; display: inline-block; margin-top: 10px;"
                  tappable
                  @click="removeItem(item)"
                />
                <div
                  style="min-width: 20px; display: inline-block; text-align: center; vertical-align: middle; margin-bottom: 8px;"
                >
                  {{ item.qty }}
                </div>
                <i
                  class="mdi mdi-plus-circle-outline text--primary ml-10"
                  style="font-size: 25px; display: inline-block; margin-top: 10px;"
                  tappable
                  :style="item.qty >= howManyInStock(item) ? 'pointer-events: none; opacity: 0.3;' : ''"
                  @click="addItem(item)"
                />
              </div>
            </ion-item>
          </div>
          <!-- cart summary -->
          <div class="text--white cart-sum-wrap ion-padding">
            <ion-item
              v-if="cartContainsOntimePayment || cartContainsSubscription"
              color="transparent"
              class="text--white cart-sum text-14"
              style="height: 35px;"
              lines="none"
              @click="openDiscountPopover"
            >
              <span
                v-if="!discount && !cuponFailed && !cuponSuccess && language === 'EN'"
                class="text--gray"
              >
                {{ $t('Have an') }} <span class="text--primary">{{ $t('discount') }}</span> {{ $t('code?') }}
              </span>
              <span
                v-if="!discount && !cuponFailed && !cuponSuccess && language === 'SE'"
                class="text--gray"
              >
                Har du en <span class="text--primary">rabattkod?</span>
              </span>
              <span
                v-if="cuponFailed"
                class="text--gray"
              >{{ $t('This') }} <span class="text--primary">{{ $t('discount code') }}</span> {{ $t('is invalid') }}</span>
              <span
                v-if="cuponSuccess"
                class="text--gray"
              ><span class="text--primary">{{ discountCupon }} (<span v-if="appplyCartDiscount(cart, discount).type === 'percents'">-{{ appplyCartDiscount(cart, discount).discountNum }}%</span><span v-else>-{{ appplyCartDiscount(cart, discount).discountNum }} kr</span>)</span>
                <i
                  class="mdi mdi-close text--danger ml-10"
                  tappable
                  @click="discountCupon = '';$event.stopPropagation();"
                /></span>
              <div slot="end">
                <i
                  v-if="cuponSuccess"
                  class="mdi mdi-check-circle-outline text--primary"
                  style="font-size: 25px;"
                />
                <i
                  v-if="cuponFailed"
                  class="mdi mdi-close-circle-outline text--danger"
                  style="font-size: 25px;"
                />
                <ion-spinner
                  v-if="checkingCupon"
                  style="max-width: 25px;"
                  color="light"
                  name="crescent"
                />
              </div>
            </ion-item>

            <ion-item
              v-if="basketSumOneTimePayment(cart).sum > 1"
              color="transparent"
              class="text--white cart-sum text-14"
              lines="none"
            >
              <span class="text--white">{{ $t('Order value') }}</span>
              <span
                slot="end"
                class="text--white"
              >
                {{ basketSumOneTimePayment(cart).sum }} kr
              </span>
            </ion-item>
            <ion-item
              v-if="cartContainsOntimePayment && discount"
              color="transparent"
              class="text--white cart-sum text-14"
              lines="none"
            >
              <span class="text--white">{{ $t('Discount') }} <span v-if="appplyCartDiscount(cart, discount).type === 'percents'">-{{ appplyCartDiscount(cart, discount).discountNum }}%</span></span>
              <span
                slot="end"
                class="text--white"
              >
                <span class="ml-5 text--primary">-{{ appplyCartDiscount(cart, discount).sum }} kr</span>
              </span>
            </ion-item>
            <ion-item
              v-if="basketSumSubscription(cart).sumUpfront > 1"
              color="transparent"
              lines="none"
              class="text--white cart-sum text-14"
            >
              <span
                class="text--white"
              >{{ $t('Upfrontcost') }}</span>
              <span
                slot="end"
                class="text--white"
              >{{ basketSumSubscription(cart).sumUpfront }} kr</span>
            </ion-item>
            <ion-item
              v-if="cartContainsSubscription"
              color="transparent"
              lines="none"
              class="text--white cart-sum text-14"
            >
              <span
                class="text--white"
              >{{ $t('Monthly subscription') }}</span>
              <span
                slot="end"
                class="text--white"
              >{{ basketSumSubscription(cart).sumSub }} kr</span>
            </ion-item>
            <ion-item
              v-if="cartContainsSubscription || cartContainsOntimePayment"
              color="transparent"
              lines="none"
              class="text--white cart-sum text-14"
            >
              <span
                class="text--white"
              >{{ $t('Delivery') }}</span>
              <span
                v-if="basketSumOneTimePayment(cart).total > 1"
                slot="end"
                class="text--white"
              >{{ calcShipping(basketSumOneTimePayment(cart).total) }} kr</span>
              <span
                v-else-if="calcShipping(basketSumOneTimePayment(cart).total) < 1"
                slot="end"
                class="text--white"
              >FREE</span>
            </ion-item>
            <hr v-if="cartContainsSubscription || cartContainsOntimePayment">
            <ion-item
              v-if="cartContainsSubscription || cartContainsOntimePayment"
              color="transparent"
              class="text--white cart-sum text-14"
              lines="none"
              style="margin-top: -15px;"
            >
              <span class="text--white">{{ $t('Total') }}</span>
              <span
                slot="end"
                class="text--white"
              >
                {{ totalCartSum }} kr
              </span>
            </ion-item>
          </div>
          <!-- up sell -->
          <div
            v-if="crossSellItems.length"
            class="text-left text--white mt-20 ion-padding-start text--bold"
          >
            Utöka ditt smarta hem
          </div>
          <ion-slides
            v-if="enableSlider"
            ref="slides"
            class="slides"
            pager="true"
            :options="upSellSliderOpts"
          >
            <ion-slide
              v-for="item in crossSellItems"
              :key="item.id"
              class="up-sell-slide"
            >
              <div
                tappable
                class="slide"
                @click="openUpSellPopOver(item)"
              >
                <img
                  class="up-sell-picture"
                  :src="item.itemPicturesAWSLinks[0]"
                >
                <div
                  class="text--white text--bold text-12"
                  style="padding-top: 5px;"
                >
                  {{ item.itemName }}
                </div>
                <div
                  v-if="item.itemName !== 'Homevision Haven' && item.itemName !== 'Homevision Essential' && item.itemName !== 'Homevision +'"
                  class="up-sell-price text--white text-14 pb-10"
                  style="margin-top: 2px;"
                >
                  {{ item.memberSubscriptionPriceMonth }} kr/mån*
                </div>
                <div
                  v-else
                  class="up-sell-price text--white text-14 pb-10"
                  style="margin-top: 2px;"
                >
                  {{ item.subscriptionPriceMonth }} kr/mån
                </div>
              </div>
            </ion-slide>
          </ion-slides>
          <!-- Cart checkout -->
          <div class="ion-padding">
            <ion-button
              :disabled="!cart.length"
              @click="goNext('calcBasket')"
            >
              {{ $t('Check out') }}
            </ion-button>
          </div>
        </div>
        <div v-else>
          <span class="text--gray mt-30">{{ $t('Cart is empty') }}</span>
        </div>
      </div>
      <div
        v-else-if="selectedView === 2"
        key="2"
        class="ion-padding-start ion-padding-end"
      >
        <div class="text-left text--gray text-14">
          {{ $t('My information') }}
        </div>
        <ion-item
          lines="none"
          color="transparent"
          detail="false"
          style="height: 70px; margin-top: 4px;"
          class="device-item rounded-corners text--white"
          button
          @click="goNext('3')"
        >
          <div
            slot="start"
            class="text-14 text--white mt-5 mb-5 ml-5"
          >
            <div class="text--primary">
              {{ userName }}
            </div>
            <div class="text-12 mt-5">
              <i class="mdi mdi-phone mr-5" />{{ phoneNumber }}
            </div>
            <div class="text-12">
              <i class="mdi mdi-at mr-5" />{{ userEmail }}
            </div>
          </div>
          <i
            slot="end"
            class="mdi mdi-chevron-right"
          />
        </ion-item>

        <div class="text-left text--gray text-14 mt-10">
          {{ $t('Shipping Address') }}
        </div>

        <ion-item
          lines="none"
          color="transparent"
          detail="false"
          style="height: 80px; margin-top: 4px;"
          class="device-item rounded-corners text--white"
          button
          @click="goNext('4')"
        >
          <div
            slot="start"
            class="text-14 text--white mt-5 mb-5 ml-5"
          >
            <div class="text--white text-12">
              {{ street }}
            </div>
            <div class="text-12">
              {{ city }}
            </div>
            <div class="text-12">
              {{ postCode }}
            </div>
            <div class="text-12">
              {{ country }}
            </div>
          </div>
          <i
            slot="end"
            class="mdi mdi-chevron-right"
          />
        </ion-item>

        <div class="mt-10">
          <div class="text-left text--gray mt-10 text-14">
            {{ $t('ShippingOptions') }}
          </div>
          <div
            style="margin-top: -6px;"
          >
            <ion-item
              lines="none"
              color="transparent"
              class="device-item rounded-corners text--white"
              :style="shippingToBoxDescription ? 'height: 75px' : ''"
              @click="goNext('5')"
            >
              <div class="text-14 ml-5">
                <div style="display: inline-block">
                  <img
                    v-if="whichShippingMethodSelected.image === 'postNord'"
                    style="max-width: 80px; position: relative; top: 4px; margin-right: 5px;"
                    :src="postNord"
                  >
                  <img
                    v-else-if="whichShippingMethodSelected.image === 'budbee'"
                    style="max-width: 70px; position: relative; top: 1px; margin-right: 5px;"
                    :src="budbee"
                  >
                </div>
                <div
                  style="display: inline-block"
                  class="text--primary"
                >
                  <span class="text--white">{{ whichShippingMethodSelected.name }}</span> {{ whichShippingMethodSelected.cost }} kr
                </div>
                <div
                  class="text--gray text-10"
                  style="margin-top: 5px;"
                >
                  {{ whichShippingMethodSelected.description }}
                </div>
                <div
                  v-if="shippingToBoxDescription"
                  class="text--white text-10"
                  style="margin-top: 5px;"
                >
                  {{ shippingToBoxDescription }}
                </div>
              </div>
              <div slot="end">
                <i class="mdi mdi-chevron-right text--white" />
              </div>
            </ion-item>
            <!-- <ion-item
              v-else
              lines="none"
              color="transparent"
              class="device-item rounded-corners text--white"
            >
              <div class="text-14 ml-5">
                <div style="display: inline-block;">
                  <img
                    style="max-width: 60px; position: relative; top: 2px; margin-right: 5px;"
                    :src="postNord"
                  >
                </div> <div
                  style="display: inline-block"
                  class="text--primary"
                >
                  FREE
                </div>
                <div
                  class="text--gray text-12"
                  style="margin-top: 0px;"
                >
                  {{ shippingMethods[1].shippingMethodDescription }}
                </div>
              </div>
              <div
                slot="end"
              >
                <i class="mdi mdi-chevron-right text--white" />
              </div>
            </ion-item> -->
          </div>
          <!-- <div
            class="text-left text--primary text-12"
          >
            {{ $t('Fri leverans på beställningar över 1 900 SEK (enbart vid köp nu)') }}
          </div> -->
        </div>

        <div class="mt-10 text-left">
          <div class="text-left text--gray text-14 mt-10">
            {{ $t('Summary') }}
          </div>
          <div
            v-for="item in cart"
            :key="item"
            style="display: inline-block; margin-top: 4px;"
          >
            <img
              :src="item.picture"
              style="max-width: 55px;"
              class="mr-5"
            >
          </div>
        </div>
        <div class="text--white cart-sum-wrap">
          <ion-item
            v-if="basketSumOneTimePayment(cart).sum > 1"
            color="transparent"
            class="text--white cart-sum text-14"
            lines="none"
            style="--padding-start: 0px; --inner-padding-end: 0px;"
          >
            <span class="text--white">{{ $t('Order value') }}</span>
            <span
              slot="end"
              class="text--white"
            >
              {{ basketSumOneTimePayment(cart).sum }} kr
            </span>
          </ion-item>
          <ion-item
            v-if="cartContainsOntimePayment && discount"
            color="transparent"
            class="text--white cart-sum text-14"
            lines="none"
            style="--padding-start: 0px; --inner-padding-end: 0px;"
          >
            <span class="text--white">{{ $t('Discount') }} <span v-if="appplyCartDiscount(cart, discount).type === 'percents'">-{{ appplyCartDiscount(cart, discount).discountNum }}%</span></span>
            <span
              slot="end"
              class="text--white"
            >
              <span class="ml-5 text--primary">-{{ appplyCartDiscount(cart, discount).sum }} kr</span>
            </span>
          </ion-item>
          <ion-item
            v-if="basketSumSubscription(cart).sumUpfront > 1"
            color="transparent"
            lines="none"
            class="text--white cart-sum text-14"
            style="--padding-start: 0px; --inner-padding-end: 0px;"
          >
            <span
              class="text--white"
            >{{ $t('Upfrontcost') }}</span>
            <span
              slot="end"
              class="text--white"
            >{{ basketSumSubscription(cart).sumUpfront }} kr</span>
          </ion-item>
          <ion-item
            v-if="cartContainsSubscription"
            color="transparent"
            lines="none"
            class="text--white cart-sum text-14"
            style="--padding-start: 0px; --inner-padding-end: 0px;"
          >
            <span
              class="text--white"
            >{{ $t('Monthly subscription') }}</span>
            <span
              slot="end"
              class="text--white"
            >{{ basketSumSubscription(cart).sumSub }} kr</span>
          </ion-item>
          <ion-item
            v-if="cartContainsSubscription || cartContainsOntimePayment"
            color="transparent"
            lines="none"
            class="text--white cart-sum text-14"
            style="--padding-start: 0px; --inner-padding-end: 0px;"
          >
            <span
              class="text--white"
            >{{ $t('Delivery') }}</span>
            <span
              v-if="basketSumOneTimePayment(cart).total > 1"
              slot="end"
              class="text--white"
            >{{ calcShipping(basketSumOneTimePayment(cart).total) }} kr</span>
            <span
              v-else-if="calcShipping(basketSumOneTimePayment(cart).total) < 1"
              slot="end"
              class="text--white"
            >FREE</span>
          </ion-item>
          <hr v-if="cartContainsSubscription || cartContainsOntimePayment">
          <ion-item
            v-if="cartContainsSubscription || cartContainsOntimePayment"
            color="transparent"
            class="text--white cart-sum text-14"
            lines="none"
            style="margin-top: -15px; --padding-start: 0px; --inner-padding-end: 0px;"
          >
            <span class="text--white text--bold">{{ $t('Total') }}</span>
            <span
              slot="end"
              class="text--white"
            >
              {{ totalCartSum }} kr
            </span>
          </ion-item>
        </div>
        <div class="text--gray text-left mt-10">
          <div class="text--white text-12">
            Har du några frågor? Vänligen kontakta
            <span
              class="text--bold text--primary"
            ><a href="mailto:support@homevision.se">Kundservice</a></span>
          </div>
          <div class="text-12 mt-5">
            Känn dig alltid trygg när du handlar på Homevision. Vi samarbetar med Stripe, en av världens ledande leverantör av betaltjänster via internet och accepterar betalningar med VISA, MasterCard och American Express.
          </div>
          <div class="text-12 mt-5">
            Våra abonnemang
            har ingen bindningstid och du kan när som helst återlämna ditt system. Vid
            uppsägning av avtalet efter 14 dagar och innan 12 månader, alternativt 12
            månader efter senaste kompletteringen av systemet, medför en återbruksavgift på
            649 kr.
            Med ”Slutför köp” godkänner du våra <span
              class="text--bold text--primary"
              tappable
              @click="openShoppingTermsAndConditions"
            >Fullständiga villkor</span>
          </div>
        </div>
        <div v-if="sessionCreated">
          <stripe-checkout
            ref="checkoutRef"
            :pk="publishableKey"
            :session-id="session"
          />
        </div>
        <ion-button
          class="mt-20"
          style="margin-bottom: 40px;"
          @click="createPayment"
        >
          {{ $t('Pay now') }}
        </ion-button>
      </div>
      <div
        v-else-if="selectedView === 3"
        key="3"
      >
        <div
          class="form--dark ion-padding-start ion-padding-end"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <ion-item
            class="item rounded-corners-5"
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Name') }}
            </ion-label>
            <ion-input
              v-model="userName"
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-item
            class="ion-margin-top rounded-corners-5"
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Your email') }}
            </ion-label>
            <ion-input
              v-model="userEmail"
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-item
            class="ion-margin-top rounded-corners-5"
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Your phone number') }}
            </ion-label>
            <ion-input
              v-model="phoneNumber"
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-button
            class="mt-10"
            @click="goBackFunc('checkout')"
          >
            {{ $t('Continue') }}
          </ion-button>
        </div>
      </div>
      <div
        v-else-if="selectedView === 4"
        key="4"
      >
        <div
          class="form--dark ion-padding-start ion-padding-end"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <ion-item
            class="ion-margin-top rounded-corners-5"
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Address') }}
            </ion-label>
            <ion-input
              v-model="street"
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-row class="ion-no-padding ion-no-margin">
            <ion-col class="col-left ion-no-padding mr-5">
              <ion-item
                class="ion-margin-top rounded-corners-5"
                lines="none"
              >
                <ion-label position="floating">
                  {{ $t('city') }}
                </ion-label>
                <ion-input
                  v-model="city"
                  enterkeyhint="done"
                  @keydown.enter="closeKeyboard"
                />
              </ion-item>
            </ion-col>
            <ion-col class="col-right ion-no-padding ml-5">
              <ion-item
                class="ion-margin-top rounded-corners-5"
                lines="none"
              >
                <ion-label position="floating">
                  {{ $t('PostCode') }}
                </ion-label>
                <ion-input
                  v-model="postCode"
                  enterkeyhint="done"
                  @keydown.enter="closeKeyboard"
                />
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-item
            class="ion-margin-top rounded-corners-5"
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Country') }}
            </ion-label>
            <ion-input
              v-model="country"
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
          <ion-button
            class="mt-10"
            @click="goBackFunc('checkout')"
          >
            {{ $t('Continue') }}
          </ion-button>
        </div>
      </div>
      <div
        v-else-if="selectedView === 5"
        key="5"
        class="ion-padding-start ion-padding-end"
      >
        <div v-if="!loadingShippingMethods">
          <ion-item
            v-for="method in shippingMethods"
            :key="method.id"
            lines="none"
            color="transparent"
            detail="false"
            style="height: 70px; margin-top: 8px;"
            class="device-item rounded-corners text--white"
            button
            @click="selectShippingMethod(method)"
          >
            <div class="text-14 ml-5">
              <div style="display: inline-block">
                <img
                  v-if="method.shippingMethodPicture === 'postNord'"
                  style="max-width: 80px; position: relative; top: 4px; margin-right: 5px;"
                  :src="postNord"
                >
                <img
                  v-else-if="method.shippingMethodPicture === 'budbee'"
                  style="max-width: 70px; position: relative; top: 1px; margin-right: 5px;"
                  :src="budbee"
                >
              </div>
              <div
                style="display: inline-block"
                class="text--primary"
              >
                <span class="text--white">{{ method.shippingMethodName }}</span> {{ method.shippingMethodPrice }} kr
              </div>
              <div
                class="text--gray text-10"
                style="margin-top: 5px;"
              >
                {{ method.shippingMethodDescription }} <span v-if="method.shippingMethodName === 'Box'"> (select the delivery box)</span>
              </div>
            </div>
            <div slot="end">
              <i
                class="mdi text--white"
                :class="showAvailableBoxes && method.shippingMethodName === 'Box' ? 'mdi-chevron-down' : 'mdi-chevron-right'"
              />
            </div>
          </ion-item>
          <div v-if="showAvailableBoxes">
            <div
              v-for="locker in budbeeBoxes"
              :key="locker.id"
              class="text-left ion-padding-start"
            >
              <ion-item
                lines="none"
                color="transparent"
                detail="false"
                style="margin-top: 8px;"
                class="budbee-item rounded-corners text--white"
                @click="selectShippingMethod({shippingMethodName: 'Box'}, boxSelected = true, locker.id, locker.label)"
              >
                <div
                  class="text--primary"
                  style="margin-top: 5px; margin-bottom: 5px;"
                >
                  <span class="text--white text-14">Box {{ locker.name }}</span>
                  <div>
                    <span class="text--gray text-12">ETA: {{ locker.estimatedDelivery.split('T')[0] }} ~{{ locker.label.split('~')[1].slice(0, -1) }}</span>
                  </div>
                  <div>
                    <span class="text--gray text-10">{{ locker.directions }}</span>
                  </div>
                </div>
                <div slot="end">
                  <i
                    class="mdi text--white mdi-chevron-right"
                  />
                </div>
              </ion-item>
            </div>
          </div>
        </div>
        <div v-else>
          <ion-spinner
            class="mt-30"
            color="light"
            name="crescent"
          /><br>
          {{ $t('Loading') }}
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue'
import { loadingController, alertController, popoverController, toastController } from '@ionic/vue'
// import { loadStripe } from '@stripe/stripe-js'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { InAppBrowser } from '@ionic-native/in-app-browser/'
import { Keyboard } from '@capacitor/keyboard'

import _debounce from 'lodash/debounce'

import stripe from '../../assets/images/stripe.png'
import swish from '../../assets/images/swish.png'
import postNord from '../../assets/images/postnord.png'
import budbee from '../../assets/images/budbee.png'
import CartDiscountPopover from '../Popovers/CartDiscountPopOver.vue'
import CartUpSellPopOver from '../Popovers/CartUpSellPopOver.vue'

export default defineComponent({
  components: {
    StripeCheckout
  },
  props: {
    close: { type: Function, default: null },
    closeAndShowItemDetail: { type: Function, default: null }
  },
  data () {
    return {
      goBack: false,
      loadingShippingMethods: false,
      loading: false,
      stripe,
      swish,
      postNord,
      budbee,
      selectedView: 1,
      userName: '',
      userEmail: '',
      phoneNumber: 0,
      city: '',
      country: '',
      street: '',
      flatNumber: '',
      postCode: '',
      discountCupon: '',
      shippingType: 'Home Delivery',
      shippingToBoxId: '',
      shippingToBoxDescription: '',
      checkingCupon: false,
      cuponSuccess: false,
      cuponFailed: false,
      discount: null,
      stripeDiscountCode: null,
      mode: 'payment',
      selectedPaymentOption: 'stripe',
      swishPaymentMethodSelected: false,
      stripePaymentMethodSelected: false,
      totalDiscount: 0,
      sessionCreated: false,
      session: '',
      sessionId: '',
      inlineItems: [],
      publishableKey: 'pk_live_51JkEZ6DZ8y8HBQ6JgND5wa84r8QNaTGBDtjlEjfAC6fCfU6OCEE8N8NZuKc9KTXQ17ZWU4CR3a5RqX2Y2wtrC3OW002Gd9E1nH',
      crossSaleData: [],
      shippingMethods: [],
      // shippingMethodIdPaid: 'shr_1JnpwgDZ8y8HBQ6JF5J1aE1g',
      // shippingMethodIdFree: 'shr_1JnqMmDZ8y8HBQ6JZvFnwtI6',
      shippingCost: 79,
      shippingMethodId: 'price_1KDvg0DZ8y8HBQ6JXrf4f3HU',
      enableSlider: false,
      upSellSliderOpts: {
        // autoPlay: true,
        // loop: true,
        autoplayDisableOnInteraction: false,
        slidesPerView: 2.5,
        spaceBetween: 0,
        initialSlide: 0,
        speed: 700
        // loop: true
      },
      crossSellItems: [
      ],
      isKitInBasket: false,
      isMember: false,
      itemsStockQTYs: [],
      budbeeBoxes: [],
      showAvailableBoxes: false
    }
  },
  computed: {
    language () {
      return this.$i18n.locale
    },
    cart () {
      console.log(this.$store.state.eshopCart)
      return this.$store.state.eshopCart
    },
    user () {
      return this.$store.state.userData
    },
    platform () {
      return this.$store.state.platform
    },
    cartContainsSubscription () {
      return this.cart.some(i => i.paymentStyle === 'subscription')
    },
    cartContainsOntimePayment () {
      return this.cart.some(i => i.paymentStyle === 'onetime' || i.productSubUpfrontPriceId)
    },
    totalCartSum () {
      let cartSum = 0
      if (this.basketSumSubscription(this.cart).sumUpfront > 1) {
        cartSum = this.basketSumOneTimePayment(this.cart).total
      } else {
        cartSum = this.basketSumOneTimePayment(this.cart).sum
      }

      let totalUpfront = 0
      if (this.basketSumSubscription(this.cart).sumUpfront > 1) {
        totalUpfront = this.basketSumSubscription(this.cart).sumUpfront
      } else {
        totalUpfront = this.basketSumSubscription(this.cart).sumSub
      }
      const discount = this.totalDiscount
      const shippingCost = this.calcShipping(cartSum)
      return cartSum + totalUpfront + shippingCost - discount
    },
    whichShippingMethodSelected () {
      const shippingMethodSelected = this.shippingMethods.filter(item => item.shippingMethodPriceId === this.shippingMethodId)[0]
      const shippingMethodsDetail = {
        name: shippingMethodSelected.shippingMethodName,
        cost: shippingMethodSelected.shippingMethodPrice,
        image: shippingMethodSelected.shippingMethodPicture,
        description: shippingMethodSelected.shippingMethodDescription
      }
      console.log(shippingMethodsDetail)
      return shippingMethodsDetail
    }
  },
  watch: {
    swishPaymentMethodSelected: function (val) {
      if (val) {
        this.selectedPaymentOption = 'swish'
        this.stripePaymentMethodSelected = false
      }
    },
    stripePaymentMethodSelected: function (val) {
      if (val) {
        this.selectedPaymentOption = 'stripe'
        this.swishPaymentMethodSelected = false
      }
    },
    discountCupon: function (val) {
      if (val.length === 0) {
        this.checkingCupon = false
        this.cuponSuccess = false
        this.cuponFailed = false
        this.cart.forEach(i => {
          i.discountedPrice = null
        })
        this.discount = null
        this.totalDiscount = 0
      } else {
        this.checkCupon(val)
        this.checkingCupon = true
        this.cuponSuccess = false
        this.cuponFailed = false
      }
    },
    selectedView: function (val) {
      if (val === 1) {
        this.inlineItems = []
      }
    },
    cart: {
      deep: true,
      handler () {
        // this.cart.forEach(item => {
        //   if (item.name === 'Homevision Haven' || item.name === 'Homevision Essential') {
        //     this.isKitInBasket = true
        //   } else {
        //     this.isKitInBasket = false
        //   }
        // })
        if (this.cart.some(item => item.itemCategory === 'Homevision Kit')) {
          this.isKitInBasket = true
        } else {
          this.isKitInBasket = false
        }
      }
    }
  },
  created () {
    this.checkCupon = _debounce(this.checkCupon, 1000)
    this.getShippingMethods()
  },
  mounted () {
    this.getCrossSaleData()
    this.getAllItemsStockQTY()
    const userData = this.user.length ? this.user[0] : {}
    this.userName = userData.name ? userData.name : ''
    this.phoneNumber = userData.phone ? userData.phone : null
    this.userEmail = userData.email ? userData.email : ''
    this.city = userData.myAddress ? userData.myAddress.city : ''
    this.country = userData.myAddress ? (userData.myAddress.country ? userData.myAddress.country : 'Sverige') : ''
    this.street = userData.myAddress ? userData.myAddress.street : ''
    this.flatNumber = userData.myAddress ? userData.myAddress.flatNumber : ''
    this.postCode = userData.myAddress ? userData.myAddress.postCode : ''
    this.isMember = this.$store.state.isMember
    // console.log(this.isMember)
  },
  methods: {
    getAllItemsStockQTY () {
      this.$store.dispatch('getAllItemsStockQTY').then(resp => {
        this.itemsStockQTYs = resp.data
        let count = 0
        this.cart.forEach(i => {
          const skuInCart = i.sku
          const qtyInCart = i.qty
          const stockAvailable = this.itemsStockQTYs.filter(stockObj => stockObj.itemSKU === skuInCart)[0]
          if (qtyInCart > stockAvailable.qtyInStock) {
            count++
            const paymentStyle = i.paymentStyle
            const availableQty = stockAvailable.qtyInStock
            this.$store.commit('removeSpecificAmountFromBasket', { item: i, paymentStyle, availableQty })
          }
        })
        if (count > 0) {
          this.displayOutOfStockAlert()
        }
      })
    },
    async displayOutOfStockAlert () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Out of stock'),
          message: this.$t('Some items are out of stock. We have modified your basket accordingly'),
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
              }
            }
          ]
        })
      return alert.present()
    },
    getCrossSaleData () {
      this.$store.dispatch('getCrossSaleData', { type: 'cross-sale' })
        .then((resp) => {
          this.crossSaleData = resp.data.items
          this.calculateCrossSale()
        })
    },
    calculateCrossSale () {
      this.crossSaleData.forEach(item => {
        if (this.cart.filter(i => i.id === item.item).length > 0) {
          item.products.forEach(item => {
            if (this.crossSellItems.filter(e => e.id === item.id).length < 1) {
              this.crossSellItems.push(item)
            }
          })
        }
      })
      if (this.cart.length) {
        this.enableSlider = true
        setTimeout(() => {
          this.$refs.slides.$el.startAutoplay()
        }, 500)
        this.cart.forEach(item => {
          if (item.itemCategory === 'Homevision Kit') {
            this.isKitInBasket = true
          }
        })
      }
    },
    goBackFunc (where) {
      this.goBack = true
      if (where === 'basket') {
        this.selectedView = 1
      } else if (where === 'checkout') {
        this.showAvailableBoxes = false
        if (this.shippingType === 'Box' && !this.shippingToBoxId) {
          this.shippingMethodId = 'price_1KCm2bDZ8y8HBQ6JhRDx5zWF'
          this.shippingCost = 79
        }
        this.selectedView = 2
      }
    },
    goNext (where) {
      this.goBack = false
      if (where === 'calcBasket') {
        this.calcBasket()
      } else if (where === '3') {
        this.selectedView = 3
      } else if (where === '4') {
        this.selectedView = 4
      } else if (where === '5') {
        this.getAvailableBudbeeBoxes(this.postCode)
        this.selectedView = 5
      }
    },
    dismissModal () {
      this.cart.forEach(i => {
        i.discountedPrice = null
      })
      this.close()
    },
    getAvailableBudbeeBoxes (postCode) {
      this.loadingShippingMethods = true
      this.$store.dispatch('getAvailableBudbeeBoxes', { postCode }).then(resp => {
        this.budbeeBoxes = resp.data.lockers
        this.loadingShippingMethods = false
      })
    },
    getShippingMethods () {
      this.$store.dispatch('getShippingMethods')
        .then((res) => {
          console.log(res.data)
          this.shippingMethods = res.data
        })
    },
    checkCupon (val) {
      this.callForCouponCheck(val)
    },
    callForCouponCheck (val) {
      this.$store.dispatch('checkDiscountCoupon', { val, cart: this.cart })
        .then((res) => {
          console.log(res)
          if (res.data.canBeDiscounted.length) {
            this.applyDiscount(res.data.canBeDiscounted)
            this.stripeDiscountCode = res.data.stripeDiscountCode
          } else {
            this.cuponFailure()
          }
        })
        .catch((err) => {
          if (err) {
            this.cuponFailure()
          }
        })
    },
    applyDiscount (discount) {
      this.cuponSuccess = true
      this.checkingCupon = false
      discount.forEach(i => {
        const index = this.cart.findIndex(cartItem => cartItem.productId === i.productId)
        this.cart[index].discountedPrice = i.discountSEK
      })
      this.discount = discount
    },
    cuponFailure () {
      if (this.discountCupon.length > 0) {
        this.cuponFailed = true
        this.checkingCupon = false
        this.discount = null
        this.cart.forEach(i => {
          if (i.discountedPrice) {
            i.discountedPrice = null
          }
        })
      }
    },
    basketSumOneTimePayment (cart) {
      let sum = 0
      let firstSubSum = 0
      cart.forEach(i => {
        // console.log(i)
        let subPrice = 0
        if (this.isMember || this.isKitInBasket) {
          if (i.name === 'Homevision Haven' || i.name === 'Homevision Essential' || i.name === 'Homevision +' || !i.memberSubscriptionPriceMonth) {
            subPrice = Number(i.subPrice)
          } else {
            subPrice = Number(i.memberSubscriptionPriceMonth)
          }
        } else {
          subPrice = Number(i.subPrice)
        }
        if (i.paymentStyle === 'onetime') {
          sum = sum + Number(i.price) * i.qty
        }
        if (i.productSubUpfrontPriceId) {
          firstSubSum = firstSubSum + subPrice * i.qty
        } else {
          firstSubSum = firstSubSum + subPrice * i.qty
        }
      })
      return { sum, firstSubSum, total: (sum + firstSubSum) }
    },
    basketSumSubscription (cart) {
      let sumSub = 0
      let sumUpfront = 0
      cart.forEach(i => {
        let subPrice = 0
        if (this.isMember || this.isKitInBasket) {
          if (i.name === 'Homevision Haven' || i.name === 'Homevision Essential' || i.name === 'Homevision +' || !i.memberSubscriptionPriceMonth) {
            subPrice = Number(i.subPrice)
          } else {
            subPrice = Number(i.memberSubscriptionPriceMonth)
          }
        } else {
          subPrice = Number(i.subPrice)
        }
        if (i.paymentStyle === 'subscription') {
          sumSub = sumSub + subPrice * i.qty
          sumUpfront = sumUpfront + Number(i.subscriptionUpfrontPrice) * i.qty
        }
      })
      return { sumUpfront, sumSub }
    },
    addItem (item) {
      const paymentStyle = item.paymentStyle
      this.$store.commit('addItemToCart', { item, paymentStyle })
    },
    removeItem (item) {
      const paymentStyle = item.paymentStyle
      if (item.qty > 1) {
        this.$store.commit('removeItemFromCart', { item, paymentStyle })
      } else {
        this.removingItem(item, paymentStyle)
      }
    },
    async removingItem (item, paymentStyle) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Remove Item'),
          message: this.$t('This action will remove item from cart'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
              }
            },
            {
              text: this.$t('Remove'),
              role: 'destructive',
              handler: () => {
                this.$store.commit('removeItemFromCart', { item, paymentStyle })
              }
            }
          ]
        })
      return alert.present()
    },
    calcBasket () {
      this.cartCalculation()
      this.selectedView = 2
      // const sum = this.basketSumOneTimePayment(this.cart).total
      // if (sum < 1500) {
      //   this.shippingMethodId = this.shippingMethodIdPaid
      // } else {
      //   this.shippingMethodId = this.shippingMethodIdFree
      // }
    },
    cartCalculation () {
      const basket = this.cart
      basket.forEach(item => {
        // console.log(item)
        if (item.paymentStyle === 'onetime') {
          this.inlineItems.push({
            price: item.productPriceId,
            quantity: item.qty
          })
        } else if (item.paymentStyle === 'subscription') {
          this.mode = 'subscription'
          let subId = ''
          if (this.isKitInBasket || this.isMember) {
            if (item.name === 'Homevision Haven' || item.name === 'Homevision Essential' || item.name === 'Homevision +' || !item.memberSubscriptionPriceMonth) {
              subId = item.productPriceSubId
            } else {
              subId = item.memberSubscriptionPriceId
            }
          } else {
            subId = item.productPriceSubId
          }
          this.inlineItems.push({
            price: subId,
            quantity: item.qty
          })
          if (item.subscriptionUpfrontPrice) {
            this.inlineItems.push({
              price: item.productSubUpfrontPriceId,
              quantity: item.qty
            })
          }
        }
      })
    },
    calcShipping (cart) {
      const shippinCost = this.shippingCost
      return shippinCost
    },
    nextStep () {
      this.selectedView = 3
    },
    createPayment () {
      this.presentLoading()
      if (this.selectedPaymentOption === 'stripe') {
        this.createStripePaymentWindow()
      }
    },
    createStripePaymentWindow () {
      // console.log(this.mode, this.userEmail, this.shippingMethodId, (this.basketSumOneTimePayment(this.cart).total + this.basketSumSubscription(this.cart).sumUpfront), this.$store.state.propertyId, this.$store.state.userName)
      this.$store.dispatch('getStripeIntents', { inlineItems: this.inlineItems, mode: this.mode, email: this.userEmail, discount: this.stripeDiscountCode, shipping: this.shippingMethodId, totalSEK: (this.basketSumOneTimePayment(this.cart).total + this.basketSumSubscription(this.cart).sumUpfront), propertyId: this.$store.state.propertyId, name: this.$store.state.userName })
        .then(async resp => {
          this.session = resp.data.paymentSession.id
          this.sessionId = resp.data.paymentSession.id
          this.sessionCreated = true
        })
        .finally(() => {
          const data = {
            discount: this.discount,
            inlineItems: this.cart,
            shipToAddress: {
              street: this.street,
              city: this.city,
              flatNumber: this.flatNumber,
              country: this.country,
              postCode: this.postCode
            },
            shippingMethod: {
              type: this.shippingType,
              box: this.shippingToBoxId,
              boxDetail: this.shippingToBoxDescription,
              shippingCost: this.shippingCost
            },
            customerEmail: this.userEmail,
            customerName: this.userName,
            customerPhone: this.phoneNumber,
            propertyId: this.$store.state.propertyId,
            sessionId: this.sessionId,
            totalPaid: this.totalCartSum
          }
          this.$store.dispatch('createOrder', data).then(() => {
            this.$refs.checkoutRef.redirectToCheckout()
            setTimeout(() => {
              this.clearWindow()
            }, 1500)
          })
        })
        .catch((error) => {
          loadingController.dismiss()
          this.presentToast(error)
        })
    },
    async presentLoading () {
      const loading = await loadingController
        .create({
          cssClass: 'gateway-disconnected-overlay',
          spinner: 'crescent'
        })

      await loading.present()
    },
    async presentToast (message) {
      console.log(message)
      const toast = await toastController.create({
        message: 'Something has failed.. Please try again',
        duration: 3000
      })
      toast.present()
    },
    activatePaymentMethod (method) {
      if (method === 'swish') {
        this.swishPaymentMethodSelected = true
        this.stripePaymentMethodSelected = false
      } else {
        this.swishPaymentMethodSelected = false
        this.stripePaymentMethodSelected = true
      }
      this.selectedPaymentOption = method
    },
    appplyCartDiscount (cart, discount) {
      // return cart - discount[0].discountSEK
      // console.log(discount)
      let sum = 0
      let discountNum = 0
      let type = ''
      cart.forEach(i => {
        if (i.paymentStyle === 'onetime') {
          const index = discount.findIndex(index => index.productId === i.productId)
          if (index === -1) {
            sum = sum + 0
          } else {
            const basketSum = (Number(i.price) * i.qty)
            if (discount[index].discountSEK) {
              type = 'number'
              discountNum = discount[index].discountSEK
              if (sum === 0) {
                sum = sum + discount[index].discountSEK
              }
            } else if (discount[index].discountPercents) {
              const discountPerc = basketSum * (discount[index].discountPercents / 100)
              type = 'percents'
              discountNum = discount[index].discountPercents
              sum = sum + discountPerc
            }
          }
        } else {
          const index = discount.findIndex(index => index.productId === i.productId)
          const basketSum = (Number(i.price) * i.qty)
          if (discount[index].discountSEK) {
            type = 'number'
            discountNum = discount[index].discountSEK
            if (sum === 0) {
              sum = sum + discount[index].discountSEK
            }
          } else if (discount[index].discountPercents) {
            const discountPerc = basketSum * (discount[index].discountPercents / 100)
            type = 'percents'
            discountNum = discount[index].discountPercents
            sum = sum + discountPerc
          }
        }
      })
      // console.log(sum)
      this.totalDiscount = sum
      return {
        sum,
        discountNum,
        type
      }
    },
    clearWindow () {
      this.selectedView = 1
      this.userName = ''
      this.userEmail = ''
      this.city = ''
      this.country = ''
      this.discount = null
      this.totalDiscount = 0
      this.street = ''
      this.flatNumber = ''
      this.postCode = ''
      this.loading = false
      this.mode = 'payment'
      this.session = ''
      this.sessionId = ''
      loadingController.dismiss()
      this.dismissModal()
    },
    async openDiscountPopover () {
      const popover = await popoverController
        .create({
          component: CartDiscountPopover,
          cssClass: 'popover-discount',
          translucent: false,
          componentProps: {
            close: (val) => this.addCouponFromPopoverAndClose(val)
          }
        })
      await popover.present()
    },
    addCouponFromPopoverAndClose (val) {
      popoverController.dismiss()
      this.discountCupon = val
    },
    async openUpSellPopOver (item) {
      const popover = await popoverController
        .create({
          component: CartUpSellPopOver,
          cssClass: 'popover',
          translucent: false,
          componentProps: {
            item: item,
            isKitInBasket: this.isKitInBasket,
            isMember: this.isMember,
            itemQTY: this.itemsStockQTYs.filter(i => i.itemSKU === item.itemSKU)[0],
            close: (item) => this.addUpSellToCartAndClose(item),
            closeAndOpenItemDetail: (item) => this.closeAndOpenItemDetail(item)
          }
        })
      await popover.present()
    },
    addUpSellToCartAndClose (item) {
      popoverController.dismiss()
      if (item) {
        const paymentStyle = item.paymentStyle
        this.$store.commit('addItemToCart', { item, paymentStyle })
      }
    },
    closeAndOpenItemDetail (item) {
      this.closeAndShowItemDetail(item)
    },
    openShoppingTermsAndConditions () {
      let linkToOpen = ''
      if (this.platform.includes('android')) {
        linkToOpen = 'https://docs.google.com/viewer?url=https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/PurchaseTermsAndConditions.pdf'
      } else {
        linkToOpen = 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/PurchaseTermsAndConditions.pdf'
      }
      InAppBrowser.create(linkToOpen).show()
    },
    closeKeyboard () {
      Keyboard.hide()
    },
    howManyInStock (item) {
      const checkingItem = this.itemsStockQTYs.filter(i => i.itemSKU === item.sku)
      return checkingItem.length ? checkingItem[0].qtyInStock : 0
    },
    selectShippingMethod (method, boxSelected, boxId, boxDescription) {
      if (method.shippingMethodName === 'Home Delivery') {
        this.shippingType = method.shippingMethodName
        this.shippingMethodId = method.shippingMethodPriceId
        this.shippingCost = method.shippingMethodPrice
        this.shippingToBoxDescription = ''
        this.goBackFunc('checkout')
      } else if (method.shippingMethodName === 'Box' && !boxSelected) {
        this.shippingType = method.shippingMethodName
        this.shippingMethodId = method.shippingMethodPriceId
        this.shippingCost = method.shippingMethodPrice
        if (this.showAvailableBoxes) {
          this.showAvailableBoxes = false
        } else {
          this.showAvailableBoxes = true
        }
      } else if (method.shippingMethodName === 'Box' && boxSelected === true) {
        this.shippingType = method.shippingMethodName
        this.shippingToBoxId = boxId
        this.shippingToBoxDescription = boxDescription
        console.log(boxId)
        this.goBackFunc('checkout')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";
.item {
    // max-width: 20px;
    height: 60px;
    padding: 0;
    margin-inline: 0 0;
    .item-inner {
        margin-inline: 0;
        padding-inline-end: 0;
        padding-inline-start: 0;
    }
}

.address-item {
    // max-width: 20px;
    height: 320px;
    padding: 0;
    margin-inline: 0 0;
    .item-inner {
        margin-inline: 0;
        padding-inline-end: 0;
        padding-inline-start: 0;
    }
}

.item-picture {
    margin-inline: 0;
    max-width: 55px;
}
.item-qty {
    border: 1px solid white;
    color: white;
    font-size: 13px;
    max-width: 35px;
    max-height: 25px;
    padding-left: 7px;
    ion-input {
      max-height: 25px;
      padding: 0;
      margin: 0;
    }
    // padding: 3px;
    // margin-inline: 0;
  //     --inner-padding-bottom: 0;
  // --inner-padding-end: 0;
  // --inner-padding-start: 0;
  // --inner-padding-top: 0;
  // --inner-margin: 0;
  // --ion-safe-area-right: 0;
  // --ion-safe-area-left: 0;
  // --ion-safe-area: 0;
  // --ion-safe-area-bottom: 0;
  //   .item-inner {
  //       margin-inline: 0;
  //       padding-inline-end: 0;
  //       padding-inline-start: 0;
  //   }
}
.cart-sum-wrap {
  margin-bottom: 20px;
  .cart-sum {
    height: 30px;
  }
}
.discount {
  background: rgba(63, 66, 74, 0.9);
  border: 1px solid rgba(134, 134, 134, 0.1);
  border-radius: 10px;
  height: 50px;
  display: flex;
  width: 250px;
  align-items: center;
  color: white;
  font-size: 16px;
  --padding-start: 10px;
  --ripple-color: transparent;
  --background-activated: transparent;
  ion-item {
    margin: 0;
    --inner-padding-end: 0;
  }
}

.ion-checkbox-marker {
    --checkmark-color:#2c3036;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    margin-top: 10px;
    border-top: 1px solid #ccc;
    padding: 0;
}
.bg-address {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.info-item {
    @include bg-box-item;
}
.up-sell-slide {
  margin-top: 8px;
 .slide {
  // min-width: 180px;
  padding: 5px;
  // @include bg-box-item;
  // background: rgba(63, 66, 74, 0.8);
  // border-radius: 10px;
 }
 .up-sell-picture {
   margin-top: 8px;
   max-width: 100px;
 }
 .up-sell-price {
   margin-top: 8px;
 }
}
</style>
