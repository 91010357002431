<template>
  <ion-page>
    <ion-header>
      <shop-header
        :cart="cart"
        :products="eshopItemsPresort"
        :open-cart="openCart"
        :disable-cart="disableCart"
      />
      <div>
        <div class="text-14">
          <ion-searchbar
            v-model="search"
            class="search"
            placeholder="  Sök produkter"
            enterkeyhint="done"
            @keydown.enter="closeKeyboard"
          />
        </div>
        <div class="ion-padding-start">
          <keep-alive>
            <swiper
              id="eshopSlides"
              ref="eshopSlides"
              class="eshopSlider"
              :slides-per-view="2.3"
              :space-between="10"
            >
              <swiper-slide
                class="h-65"
                :class="categoryActive('All Items') ? 'h-65-active' : ''"
                @click="selectCategory('All Items')"
              >
                Alla produkter
              </swiper-slide>
              <swiper-slide
                v-for="item in itemCategories"
                :key="item"
                class="h-65 text-16"
                :class="categoryActive(item) ? 'h-65-active' : ''"
                @click="selectCategory(item)"
              >
                {{ item }}
              </swiper-slide>
            </swiper>
          </keep-alive>
        </div>
      </div>
    </ion-header>
    <ion-content>
      <div v-if="selectedView === '1'">
        <div class="mt-10">
          <div class="items ion-margin-top">
            <transition-group
              name="flip-list"
              tag="p"
            >
              <div
                v-for="item in eshopItemsSorted"
                :key="item.id"
              >
                <ion-row
                  v-if="item.itemEnabled"
                  class="item"
                  :class="item.showMore ? 'showMore' : 'showLess'"
                  @click="openItemModal(item.id, item)"
                >
                  <ion-col
                    size="5"
                  >
                    <div>
                      <img
                        class="product-img"
                        :src="(item.itemPicturesAWSLinks[0])"
                      >
                    </div>
                  </ion-col>
                  <ion-col v-if="item.itemEnabled">
                    <ion-row>
                      <div class="text--white text-left mt-20 text-16">
                        <div class="text--bold">
                          {{ item.itemName }}
                        </div>
                        <div
                          v-if="item.itemPrice"
                          class="text--bold text--primary mt-10 text-14"
                        >
                          {{ formatNumber(item.itemPrice) }} kr
                          <span
                            v-if="item.itemCategory !== 'Tillbehör' && item.subscriptionPriceMonth"
                            class="text-12 text--gray"
                          > {{ $t('or') }} {{ item.subscriptionPriceMonth }} kr/mån</span>
                        </div>
                        <div
                          v-else-if="!item.itemPrice"
                          class="text--primary text-left mt-10 text-14"
                        >
                          <div
                            v-if="item.itemCategory !== 'Homevision Kit'"
                            class="text--bold"
                          >
                            <span :style="isMember !== true && typeof item.memberSubscriptionPriceMonth !== Number ? '' : 'text-decoration: line-through; font-size: 12px; color: rgba(217, 225, 217, 0.5);'">{{ item.subscriptionPriceMonth }} <span> kr</span></span>
                            <span
                              v-if="isMember !== true && item.memberSubscriptionPriceMonth"
                              class="text-12 text--gray"
                            >/mån </span>
                            <span
                              v-if="item.itemName !== 'Homevision Haven' && item.itemName !== 'Homevision Essential' && isMember !== true && item.memberSubscriptionPriceMonth"
                              class="text--gray text-12"
                            >
                              {{ $t('or') }}
                            </span> <span v-if="item.itemName !== 'Homevision Haven' && item.itemName !== 'Homevision Essential' && item.memberSubscriptionPriceMonth">{{ item.memberSubscriptionPriceMonth }} kr
                            </span>
                            <span class="text-12 text--gray">/mån</span><span
                              v-if="item.memberSubscriptionPriceMonth"
                              class="text-12 text--gray"
                            >*</span>
                          </div>
                          <div
                            v-else
                            class="text--bold"
                          >
                            <span>{{ item.subscriptionPriceMonth }}</span> <span>kr <span class="text-12 text--gray">/mån</span></span>
                          </div>
                          <div
                            v-if="item.subscriptionUpfrontPrice"
                            class="text--white text-10"
                          >
                            <span class="text--bold">{{ item.subscriptionUpfrontPrice }} kr</span> <span class="text-10 text--bold text--gray">{{ $t('Sign up fee') }}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="howManyInCart(item) > 0"
                        style="position: absolute; top: 51px; right: 10px;"
                      >
                        <i
                          class="mdi mdi-cart-arrow-down text--primary"
                          style="font-size: 18px;"
                        />
                        <!-- <span
                      class="text--white"
                      style="font-size: 18px;"
                    >{{ howManyInCart(item) }}</span> -->
                      </div>
                    </ion-row>
                    <div class="text--white text-left mt-5 text-12 product-description">
                      <span>{{ item.itemShortDescription }}</span>
                      <!-- <span>{{ item.showMore ? item.itemDescription : trimText(item.itemDescription) }}</span>
                  <a
                    v-if="item.itemDescription.length > 100"
                    @click="item.showMore = !item.showMore; $event.stopPropagation()"
                  >
                    Visa <span>{{ item.showMore ? 'mindre' : 'mer' }}</span>
                  </a> -->
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </transition-group>
            <ion-row style="height: 80px;" />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { modalController } from '@ionic/vue'

import { InAppBrowser } from '@ionic-native/in-app-browser/'
import { Keyboard } from '@capacitor/keyboard'

import { Swiper, SwiperSlide } from 'swiper/vue'

import _ from 'lodash'

import shopHeader from '../components/Headers/ShopHeaderComponent.vue'
import productDetailModal from '../components/Eshop/ProductDetail.vue'
import cartModal from '../components/Eshop/Cart.vue'

export default {
  name: 'EShop',
  components: {
    shopHeader,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      selectedView: '1',
      search: '',
      disableCart: false,
      detail: {},
      selectedCategory: 'All Items',
      eshopData: [],
      eshopItemsPresort: [],
      itemCategories: [],
      products: []
    }
  },
  computed: {
    cart () {
      return this.$store.state.eshopCart
    },
    eshopItems () {
      const items = this.eshopItemsPresort
      return items.filter(item => {
        return item.itemName.toLowerCase().includes(this.search.toLowerCase()) ||
        //  item.itemDescription.toLowerCase().includes(this.search.toLowerCase()) ||
               item.itemCategory.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    isMember () {
      return this.$store.state.isMember
    },
    eshopItemsSorted () {
      return _.orderBy(this.eshopItems, 'index')
    },
    pullEshopDataRequest () {
      return this.$store.state.pullEshopData
    }
  },
  watch: {
    $route: function () {
      if (this.$route.name === 'eShop' && this.userRole !== 'Landlord') {
        this.initSwiper()
      }
    },
    pullEshopDataRequest: function (val) {
      if (val) {
        console.log('updating eshop')
        this.pullEshopData()
      }
    }
  },
  created () {
    this.pullEshopData()
  },
  methods: {
    initSwiper () {
      const swip = document.querySelector('.eshopSlider').swiper
      swip.update()
      setTimeout(() => {
        swip.update()
      }, 500)
    },
    pullEshopData () {
      this.$store.dispatch('pullEshopData').then(resp => {
        if (resp) {
          const data = resp.data
          this.eshopData = data
          this.eshopItemsPresort = data
          this.products = data
          const categories = []
          this.eshopData.forEach(item => {
            if (!categories.includes(item.itemCategory)) {
              categories.push(item.itemCategory)
            }
          })
          this.itemCategories = categories
        }
      })
    },
    categoryActive (item) {
      let result = false
      if (item === this.selectedCategory) {
        result = true
      }
      return result
    },
    selectCategory (category) {
      if (category === 'All Items') {
        this.eshopItemsPresort = this.eshopData
      } else {
        this.eshopItemsPresort = this.eshopData.filter(item => item.itemCategory === category)
      }
      this.selectedCategory = category
    },
    openLink (link) {
      InAppBrowser.create(link).show()
    },
    trimText (text) {
      const length = 100
      return text.length > length
        ? text.substring(0, length) + '...'
        : text
    },
    openItemModal (itemId, item) {
      return modalController
        .create({
          component: productDetailModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            itemId,
            itemSKU: item.itemSKU,
            closeAndOpenCartModal: () => this.closeAndOpenCart(),
            products: this.products,
            close: () => modalController.dismiss()
          }
        })
        .then(m => m.present())
    },
    howManyInCart (item) {
      let result = 0
      this.cart.forEach(i => {
        if (i.id === item.id) result++
      })
      return result
    },
    closeAndOpenCart () {
      this.disableCart = true
      modalController.dismiss().then(
        setTimeout(() => {
          this.openCart()
          this.disableCart = false
        }, 500)
      )
    },
    openCart () {
      return modalController
        .create({
          component: cartModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => modalController.dismiss(),
            closeAndShowItemDetail: (item) => this.closeAndShowItemDetail(item)
          }
        })
        .then(modal => modal.present())
    },
    closeAndShowItemDetail (item) {
      this.openItemModal(item.id, item)
    },
    formatNumber (num) {
      return new Intl.NumberFormat('sv-SE').format(num)
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import '~@/theme/_backgrounds';
.search {
  margin-top: -15px;
  --background: #2c3036;
  text-align: left;
  --color: white;
  --icon-color: white;
  max-width: 400px;
}

.items{
  padding:0 8px;
  max-width: 520px;
  .product-img {
    max-height: 100px;
    margin-top: 20px;
  }
}
.h-65 {
    max-width: 150px;
    height: 54px;
    color: white;
    font-size: 16px;
    background-color: #35393f9c;
    text-align: center;
    border-radius: 5px;
    line-height: 50px;
}
.h-65-active {
  border: 1px solid #ffd4a4;
  border-radius: 5px;
      background: #3f424a;
    color: #ffd4a4;
}
.detail-picture {
  margin: 0 auto;
  max-width: 350px;
}
.product-description {
transition: all 0.5s ease;
}
.showMore {
  height: 230px;
  transition: height 0.15s ease-out;
  -webkit-transition: height 0.15s ease-out;
}
.showLess {
  height: 130px;
  transition: height 0.15s ease-in;
  -webkit-transition: height 0.15s ease-in;
}

.flip-list-move {
  transition: transform 0.8s ease;
}
// .flip-list-item {
//   display: inline-block;
//   margin-right: 10px;
// }
// .flip-list-enter-active,
// .flip-list-leave-active {
//   transition: all 0.3s ease;
// }
// .flip-list-enter-from,
// .flip-list-leave-to {
//   opacity: 0;
//   transform: translateY(30px);
// }

</style>
