<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-item
        slot="start"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-title class="text-18 text--white" />
      <ion-item
        slot="end"
        lines="none"
        color="transparent"
        size="large"
        class="close-modal-button"
        style="margin-right: 6px; margin-top: -2px;"
        @click="closeAndOpenCartModal"
      >
        <i
          class="mdi mdi-cart text--white"
          style="font-size: 25px;"
        />
      </ion-item>
      <ion-badge
        slot="end"
        class="text--dark"
        style="position: absolute; top: 10px; right: 16px; padding: 4px; z-index: 1000; font-size: 10px; border-radius: 30%;"
        @click="closeAndOpenCartModal"
      >
        {{ cartContentItems }}
      </ion-badge>
    </ion-toolbar>
    <div>
      <div class="ion-padding-start in-padding-end">
        <div class="text-left">
          <ion-badge color="medium">
            {{ item.itemSKU }}
          </ion-badge>
        </div>
        <div class="text--white text-left text-20 text--bold mt-10 text--uppercase">
          {{ item.itemName }}
        </div>
      </div>
      <ion-segment
        swipe-gesture="true"
        :value="Number(selectedViewSub)"
        class="mt-10"
        @ionChange="selectedViewSub = Number($event.target.value)"
      >
        <ion-segment-button value="1">
          <ion-label>
            <span class="text-16">{{ $t('Details') }}</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="2">
          <ion-label>
            <span class="text-16">{{ $t('Features') }}</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="3">
          <ion-label>
            <span class="text-16">{{ $t('Specs') }}</span>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
  </ion-header>
  <ion-content class="ion-padding-start ion-padding-end">
    <div v-if="selectedViewSub === 1">
      <div class="item-image-wrap slides">
        <ion-slides
          v-if="mounted"
          ref="slidesDetail"
          class="slidesDetail"
          pager="true"
          :options="sliderOpts"
        >
          <ion-slide
            v-for="picture in item.itemPicturesAWSLinks"
            :key="picture"
          >
            <img
              class="item-image"
              :src="picture"
              rel="preload"
            >
          </ion-slide>
        </ion-slides>
      </div>
      <div
        class="text--white text-left text-14"
        style="padding-left: 5px; padding-right: 5px;"
      >
        <dynamic :template="item.itemDescription" />
      </div>
    </div>
    <div v-else-if="selectedViewSub === 2">
      <div
        class="text--white text-left text-14"
        style="padding-left: 5px; padding-right: 5px;"
      >
        <dynamic :template="item.itemFeatures" />
      </div>
    </div>
    <div v-else-if="selectedViewSub === 3">
      <div
        class="text--white text-left text-14"
        style="padding-left: 5px; padding-right: 5px;"
      >
        <dynamic :template="item.itemSpecs" />
      </div>
    </div>
  </ion-content>
  <ion-footer>
    <ion-toolbar style="z-index: 100; min-height: 150px; --background: transparent; margin-bottom: 15px">
      <div class="item-price">
        <ion-row style="margin-bottom: 0;">
          <ion-col
            size="7"
            class="ion-no-padding"
            style="margin-bottom: 0;"
          >
            <ion-row
              v-if="paymentStyle === 'subscription'"
            >
              <span
                class="ml-10 mt-5 text--primary"
                style="font-weight: 600; font-size: 45px;"
              >
                <span v-if="!isMember">{{ formatNumber(item.subscriptionPriceMonth) }}</span>
                <span v-else-if="isMember && item.itemCategory !== 'Homevision Kit'">{{ formatNumber(item.memberSubscriptionPriceMonth) }}</span>
                <span v-else>{{ formatNumber(item.subscriptionPriceMonth) }}</span>
              </span>
              <span
                v-if="item.subscriptionUpfrontEnabled"
                class="text--primary"
                style="font-size: 14px; font-weight: 600; margin-top: 15px; margin-left: 0px;"
              >
                <span
                  class="ml-5 text-16"
                >kr</span>
                <span
                  style="margin-left: 5px;"
                  class="text--gray text-14"
                > /{{ $t('perMonth') }}</span>
                <div
                  v-if="item.subscriptionUpfrontEnabled"
                  style="margin-left: 5px; margin-top: 3px;"
                  class="text--gray text-12"
                >
                  {{ item.subscriptionUpfrontPrice }} kr {{ $t('Sign up fee') }}
                </div>
              </span>
              <span
                v-else-if="!item.subscriptionUpfrontEnabled"
                class="text--primary"
                style="font-size: 14px; font-weight: 600; margin-top: 18px; margin-left: 0px;"
              >
                <span
                  class="ml-5"
                >kr</span>
                <div
                  style="margin-left: 5px; margin-top: 0px;"
                  class="text--gray text-14"
                >{{ $t('perMonth') }}</div>
              </span>
            </ion-row>
            <ion-row
              v-else-if="paymentStyle === 'onetime' && item.itemPrice"
            >
              <span
                class="ml-10 text--primary"
                style="font-size: 40px; font-weight: 600;"
              >
                {{ formatNumber(item.itemPrice.split('.')[0]) }}
              </span>
              <span
                class="text--primary"
                style="font-size: 14px; font-weight: 600; margin-top: 10px; margin-left: 0px;"
              >
                <span v-if="typeof item.itemPrice.split('.')[1] === 'number'">.{{ item.itemPrice.split('.')[1] }} kr</span>
                <span
                  v-else
                  class="ml-5"
                >kr</span>
                <!-- <div
                  style="margin-top: -1px; margin-left: 5px;"
                  class="text--gray text-14"
                >one time</div> -->
              </span>
            </ion-row>
          </ion-col>
          <ion-col
            size="5"
            class="ion-no-padding"
            style="margin-bottom: 0;"
          >
            <div
              v-if="itemOptions.length > 1"
              class="ion-no-padding text--white"
              style="margin-top: 0px; display:flex; flex-direction: row;"
            >
              <ion-item
                lines="none"
                color="transparent"
                class="selector rounded-corners text--white"
              >
                <ion-select
                  v-model="paymentStyle"
                  @ionChange="paymentStyle = $event.target.value"
                >
                  <ion-select-option
                    v-for="{name, value} in itemOptions"
                    :key="value"
                    :value="value"
                  >
                    {{ name }}
                  </ion-select-option>
                </ion-select>
                <i class="mdi mdi-chevron-down text--primary" />
              </ion-item>
            </div>
            <!-- <div
              v-else
              class="text-12 text--gray mt-10"
            >
            </div> -->
          </ion-col>
        </ion-row>
        <div
          v-if="item.itemCategory !== 'Tillbehör' && item.memberSubscriptionPriceMonth"
          class="text--white text--bold text-12"
          style="margin-left: 12px; margin-bottom: 5px; margin-top: 0px;"
        >
          <span v-if="item.itemCategory !== 'Homevision Kit' && !isMember">{{ $t('Homevision member price') }} <span class="text--primary">{{ item.memberSubscriptionPriceMonth }} kr/ mån</span></span>
          <span v-if="item.itemCategory !== 'Homevision Kit' && isMember">{{ $t('Non-member price') }} <span class="text--primary">{{ formatNumber(item.subscriptionPriceMonth) }}kr/ mån</span></span>
        </div>
        <div
          class="text--gray text-12"
          style="margin-left: 10px; margin-bottom: 20px;"
        >
          <div
            v-if="stockQTY > 0"
            class="text--primary"
            style="display: inline-block;"
          >
            <i class="mdi mdi-circle-medium" />{{ $t('In Stock') }}
          </div>
          <div
            v-else
            class="text--primary"
            style="display: inline-block;"
          >
            <i class="mdi mdi-circle-medium" />{{ $t('Out of stock') }}
          </div>
          <div style="display: inline-block; margin: 0 10px">
            |
          </div>
          <div style="display: inline-block;">
            {{ $t('delivery') }}
          </div>
        </div>
        <!-- <div
          class="text--white text--bold text-12"
          style="margin-left: 15px; margin-bottom: 20px; margin-top: 5px;"
        >
          <span v-if="item.itemCategory !== 'Homevision Kit' && !isMember">{{ $t('Homevision member price') }} <span class="text--primary">{{ item.memberSubscriptionPriceMonth }} kr/ mån</span></span>
          <span v-if="item.itemCategory !== 'Homevision Kit' && isMember">{{ $t('Non-member price') }} <span class="text--primary">{{ formatNumber(item.subscriptionPriceMonth) }}kr/ mån</span></span>
        </div> -->
      </div>
      <div
        class="ion-padding-start ion-padding-end"
        style="margin-top: -10px;"
      >
        <ion-button
          class="button"
          :disabled="stockQTY < 1 || howManyInCart(item) >= stockQTY"
          @click="addToCart(item, paymentStyle); openPopover('added')"
        >
          <span class="text--dark">
            <i
              style="font-size: 19px;"
              class="mdi mdi-cart mr-10"
            />
            {{ $t('Add to cart') }} <span v-if="howManyInCart(item) > 0">({{ howManyInCart(item) }})</span>
          </span>
        </ion-button>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import { popoverController, modalController } from '@ionic/vue'
import { h } from 'vue'

import itemAdded from '../Popovers/EshopItemAdded.vue'

const loading = {
  template: '<div>Loading...</div>'
}
const dynamic = {
  functional: true,
  props: ['template'],
  render () {
    const template = this.template
    const component = template ? { template } : loading
    return h(component)
  }
}
export default ({
  components: {
    dynamic
  },
  props: {
    itemId: { type: Number, default: null },
    itemSKU: { type: String, default: null },
    products: { type: Array, default: () => [] },
    closeAndOpenCartModal: { type: Function, default: null },
    close: { type: Function, default: null }
  },
  data () {
    return {
      mounted: false,
      item: [],
      selectedViewSub: 1,
      paymentStyle: '',
      itemOptions: [],
      stockQTY: 1,
      sliderOpts: {
        // autoPlay: true,
        // loop: true,
        loop: false
      }
    }
  },
  computed: {
    cart () {
      return this.$store.state.eshopCart
    },
    platform () {
      return this.$store.state.platform
    },
    cartContentItems () {
      let result = 0
      this.cart.forEach(i => {
        result = result + i.qty
      })
      return result
    },
    isMember () {
      return this.$store.state.isMember
    }
  },
  watch: {
  },
  mounted () {
    if (this.item.productPriceId) {
      this.itemOptions.push({ name: this.$t('One payment'), value: 'onetime' })
      this.paymentStyle = 'onetime'
    }
    if (this.item.productPriceSubId) {
      this.itemOptions.push({ name: this.$t('Subscription'), value: 'subscription' })
      if (!this.paymentStyle) {
        this.paymentStyle = 'subscription'
      }
    }
    this.$store.dispatch('getItemStockQTYs', { itemSKU: this.itemSKU }).then(resp => {
      this.stockQTY = resp.data.qtyInStock
    })
    this.mounted = true
    setTimeout(() => {
      this.$refs.slidesDetail.$el.update()
    }, 400)
  },
  created () {
    this.item = this.products.filter(item => item.id === this.itemId)[0]
  },
  methods: {
    dismissModal () {
      this.close()
    },
    async openPopover (type) {
      const popover = await popoverController
        .create({
          component: itemAdded,
          cssClass: 'item-added-to-cart-popover',
          translucent: false,
          componentProps: {
            type
          }
        })
      await popover.present()
      setTimeout(() => {
        popover.dismiss()
      }, 800)

      const { role } = await popover.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    closeModal () {
      modalController.dismiss()
    },
    howManyInCart (item) {
      let result = 0
      const index = this.cart.findIndex(i => i.id === item.id)
      if (index === -1) {
        result = 0
      } else {
        result = this.cart[index].qty
      }
      return result
    },
    addToCart (item, paymentStyle) {
      this.$store.commit('addItemToCart', { item, paymentStyle })
    },
    removeItemFromCart (item) {
      this.$store.commit('removeItemFromCart', item)
    },
    formatNumber (num) {
      return new Intl.NumberFormat('sv-SE').format(num)
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";
ion-toolbar {
  --border-style: unset !important;
  --border-width: unset !important;
  border: 0;
}
.item-image-wrap {
    display: flex;
    justify-content: center;
    min-height: 250px;
    max-height: 250px;
    .item-image {
      max-height: 250px;
      min-height: 250px;
      margin-bottom: 0px;
      padding-bottom: 20px;
    }
}
.button {
  --ripple-color: #{colors(primary, base)};
  --background-activated: #{colors(primary, base)};
  --background-activated-opacity: 1;
  --background-focused:#{colors(primary, base)};
  --background-focused-opacity:1;
  --background-hover: #{colors(primary, base)};
  --background-hover-opacity:1;
  // max-width: 90%;
}
.item-price {
  margin-left: 10px;
}
.selector {
  // @include bg-box-room-device
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // background: rgba(63, 66, 74, 0.8);
  // border-radius: 5px;
  width: 160px;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
  --ripple-color: transparent;
  --background-activated: transparent;
      --inner-padding-end: 0;
      margin: 0;
  ion-item {
    margin: 0;
  }
}
</style>
