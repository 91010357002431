
<template>
  <ion-content
    :scroll-y="false"
    :scroll-x="false"
    class="ion-padding"
  >
    <div
      tappable
      style="position: absolute; top: 0px; left: 15px;"
      @click="dismissPopover()"
    >
      <img src="../../assets/images/arrow-go-back-line.svg">
    </div>
    <div class="name text--white text--bold">
      {{ item.itemName }}
    </div>

    <div>
      <img
        style="max-width: 250px;"
        :src="item.itemPicturesAWSLinks[0]"
      >
    </div>
    <div
      tappable
      class="text--white mt-10 text--bold"
      @click="closeOpenItemDetail(item)"
    >
      {{ $t('Show Detail') }}
    </div>
    <div class="text--white mt-30">
      <div
        :style="[isMember || isKitInBasket ? 'text-decoration: line-through; text-decoration-color: white; font-size: 14px;' : '', item.itemCategory === 'Homevision Kit' ? 'text-decoration: unset; font-size: 16px;' : '']"
        class="text--bold"
        style="display: inline-block;"
      >
        {{ item.subscriptionPriceMonth }} kr / mån
      </div>
      <div
        v-if="item.itemCategory !== 'Homevision Kit'"
        class="text--primary text--bold"
        :style="!isMember && !isKitInBasket ? 'text-decoration: line-through; text-decoration-color: #ffd4a4; font-size: 14px;' : ''"
        style="display: inline-block; margin-left: 10px;"
      >
        {{ item.memberSubscriptionPriceMonth }} kr / mån*
      </div>
      <div v-if="item.itemCategory !== 'Homevision Kit'">
        <div
          v-if="!isMember && !isKitInBasket"
          class="text-12 mt-5 text--primary"
        >
          *{{ $t('Sign up for a Homevision Kit subscription to benefit from our member prices') }}
        </div>
        <div
          v-if="!isMember && isKitInBasket"
          class="text-12 mt-5"
        >
          *{{ $t('Since you have added a Homevision kit to your basket, you can benefit from our member prices!') }}
        </div>
        <div
          v-if="isMember"
          class="text-12 mt-5"
        >
          *{{ $t('As a Homevision Kit subscriber, you always get our best price!') }}
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer
    no-border
    transparent
    class="footer"
  >
    <!-- <ion-button
      fill="clear"
      color="transparent"
      @click="dismissPopover()"
    >
      <ion-label color="primary">
        {{ $t('Cancel') }}
      </ion-label>
    </ion-button> -->
    <div
      v-if="itemQTY.qtyInStock < 1"
      class="text--primary text-14 mb-5 text-center"
    >
      <i
        class="mdi mdi-close text--danger"
        style="font-size: 16px; position: relative; top: 1px;"
      />{{ $t('Out of stock') }}
    </div>
    <ion-button
      :disabled="itemQTY.qtyInStock < 1"
      @click="addToCart(item)"
    >
      <ion-label color="dark">
        <i class="mdi mdi-cart mr-10" />{{ $t('Add to cart') }}
      </ion-label>
    </ion-button>
  </ion-footer>
</template>

<script>
import { IonContent } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PopoverUpSell',
  components: { IonContent },
  props: {
    isKitInBasket: { type: Boolean, default: false },
    isMember: { type: Boolean, default: false },
    item: { type: Object, default: null },
    close: { type: Function, default: null },
    itemQTY: { type: Object, default: null },
    closeAndOpenItemDetail: { type: Function, default: null }
  },
  data () {
    return {
      discountCoupon: '',
      placeholder: this.$t('discountCode')
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
    console.log(this.itemQTY)
  },
  methods: {
    dismissPopover (item) {
      this.close(item)
    },
    addToCart (item) {
      this.$store.commit('addItemToCart', { item, paymentStyle: 'subscription' })
      this.dismissPopover()
    },
    closeOpenItemDetail (item) {
      this.closeAndOpenItemDetail(item)
    }
  }
})
</script>

<style lang="scss" scoped>
.name {
    margin-top: 10px;
}
img {
    margin-top: 20px;
}
</style>
