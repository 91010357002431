<template>
  <ion-content
    :scroll-y="true"
    class="ion-padding"
  >
    <div class="background ion-text-center">
      <div class="container">
        <i
          class="mdi mdi-check-all text--primary"
          style="font-size: 76px;"
        /><br>
        <span
          v-if="type === 'added'"
          class="text--white"
        >{{ $t('item added') }}</span>
        <span
          v-else-if="type === 'removed'"
          class="text--white"
        >item removed</span>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Popover',
  props: {
    type: { type: String, default: null }
  },
  data () {
    return {

    }
  },
  computed: {},
  created () {
  },
  methods: {
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-content {
    --background: transparent;
    --offset-bottom: auto!important;
    --overflow: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
    display: none;
  }
}
.background {
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: 170px;
    width: 170px;
    background: #{colors(primary, base)};
    border-radius: 50%;
.container {
    margin: 0 auto;
    height: 150px;
    width: 150px;
    background: #{colors(secondary, base)};
    border-radius: 50%;
    filter: drop-shadow(0 0 0.75rem #{colors(secondary, base)});
}
}
</style>
