
<template>
  <ion-content
    :scroll-y="false"
    :scroll-x="false"
    class="ion-padding"
  >
    <ion-item
      class="rounded-corners discount text-14"
      color="transparent"
      lines="none"
    >
      <ion-input
        v-model="discountCoupon"
        :placeholder="placeholder"
      />
    </ion-item>
  </ion-content>
  <ion-footer
    no-border
    transparent
    class="footer"
  >
    <ion-button
      fill="clear"
      color="transparent"
      @click="dismissPopover(discountCoupon)"
    >
      <ion-label color="primary">
        OK
      </ion-label>
    </ion-button>
  </ion-footer>
</template>

<script>
import { IonContent } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PopoverDiscount',
  components: { IonContent },
  props: {
    close: { type: Function, default: null }
  },
  data () {
    return {
      discountCoupon: '',
      placeholder: this.$t('discountCode')
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  methods: {
    dismissPopover (discountCoupon) {
      this.close(discountCoupon)
    }
  }
})
</script>

<style lang="scss" scoped>
.discount {
  background: rgba(63, 66, 74, 0.9);
  border: 1px solid rgba(134, 134, 134, 0.1);
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  --padding-start: 10px;
  --ripple-color: transparent;
  --background-activated: transparent;
  ion-item {
    margin: 0;
    --inner-padding-end: 0;
  }
}
</style>
